<script>
	export default {
		onLaunch: function() {},
		onShow: function() {},
		onHide: function() {}
	};
</script>

<style lang="scss">
	/*每个页面公共css */
	@import '@/uni_modules/uv-ui-tools/index.scss';
	@import '@/static/index.css';

	.empty {
		display: flex;
		justify-content: center;
		padding: 120rpx 0 20rpx 0;

		.image {
			width: 300rpx;
			height: 300rpx;
			display: block;
		}
	}

	uni-toast .uni-toast .uni-toast__icon {
		color: #479CFC;
		font-size: 55px;

		path {
			fill: #479CFC !important;
		}
	}

	uni-toast .uni-toast {
		font-size: 30rpx !important;
		background: rgb(255 255 255);
		color: #479CFC;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

	}
</style>