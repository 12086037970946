import App from './App'
// 引入uvUI
import uvUI from '@/uni_modules/uv-ui-tools'

// #ifndef VUE3
import Vue from 'vue'
Vue.config.productionTip = false
App.mpType = 'app'
Vue.use(uvUI);
try {
	function isPromise(obj) {
		return (!!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function");
	}
	// 统一 vue2 API Promise 化返回格式与 vue3 保持一致
	uni.addInterceptor({
		returnValue(res) {
			if (!isPromise(res)) {
				return res;
			}
			return new Promise((resolve, reject) => {
				res.then((res) => {
					if (res[0]) {
						reject(res[0]);
					} else {
						resolve(res[1]);
					}
				});
			});
		},
	});
} catch (error) {}
const app = new Vue({
	...App
})
app.$mount()
// #endif

// #ifdef VUE3
import { createSSRApp } from 'vue'
// 引入状态管理（如果需要请打开注释）
import { setupStore, store } from '@/store'

export function createApp() {
	const app = createSSRApp(App)
	app.use(uvUI)
	setupStore(app)
	return {
		app,
		store
	}
}
// #endif